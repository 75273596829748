<template>
    <v-container>
        <AdBTWR v-if="!user.subscribed"/>

        <v-container class="px-0">
            <v-row justify="center">
                <v-col cols="12" sm="10">
                    <h1 class="page-header font-weight-light mb-5">Get a GMRS License</h1>

                    <h2 class="headline font-weight-light mb-5">General Mobile Radio Service (GMRS) Filing
                        Instructions</h2>
                    <p>An FCC license is required to operate GMRS system. Licenses are issued for a ten-year term and
                        can be
                        renewed between 90 days prior to the expiration date and up to the actual expiration date of the
                        license. After a license expires, an individual must request a new GMRS license.</p>
                    <p>A GMRS system licensed to a non-individual prior to July 31, 1987 is also eligible for renewal,
                        but
                        the
                        licensee may not make any major modification to the system.</p>
                    <p>You may apply for a GMRS license if you are 18 years or older and not a representative of a
                        foreign
                        government. If you receive a license, any family member, regardless of age, can operate GMRS
                        stations
                        and units within the licensed system.</p>
                    <p>Per Public Notice DA 15-72, the FCC no longer mails license authorizations. If you provide an
                        email
                        address on your application, an official copy of your license will be automatically emailed to
                        you
                        after
                        the application has been granted.</p>

                    <div class="text-center mb-5">
                        <InArticleAdsense
                            data-ad-client="ca-pub-2391363374702677"
                            data-ad-slot="4051888556"
                            v-if="!user.subscribed"
                        >
                        </InArticleAdsense>
                    </div>

                    <h3 class="font-weight-light mt-10 mb-3">Steps to Filing an Application for a new GMRS Station
                        license
                        in the Universal Licensing System
                        (ULS):</h3>

                    <ol class="mb-5 mx-10">
                        <li>
                            <p>Go to&nbsp;<a href="https://wireless2.fcc.gov/UlsEntry/licManager/login.jsp"
                                             target="_blank">ULS
                                License Manager</a>&nbsp;and
                                log in with your FCC Registration Number (FRN) and password. After entering your
                                password,
                                proceed
                                to
                                Step 2 below to begin filing the application.</p>

                            <p><strong>If you do not have an FRN</strong>, you can register for one here:&nbsp;<a
                                href="https://apps.fcc.gov/coresWeb/publicHome.do" target="_blank">FCC Registration
                                System</a>&nbsp;or
                                by clicking the “Register with the FCC” link under the Submit button on the Log In page.
                            </p>
                            <p><strong>NOTE:</strong>&nbsp;You will be prompted to register and verify a username
                                account
                                prior to
                                obtaining your FRN.</p>
                            <p><strong>If you do not know your FRN</strong>, you can search for it by entering your call
                                sign
                                here:<br>
                                <a href="http://wireless2.fcc.gov/UlsApp/UlsSearch/searchLicense.jsp" target="_blank">ULS
                                    License Search</a>&nbsp;or
                                by
                                clicking the “Check your licenses” link under the submit button on the Log In page.</p>
                            <p><strong>If you do not know the password:</strong></p>

                            <ul class="mx-5">
                                <li>Click on the Contact Tech Support link under the Submit button on the Log In page.
                                </li>
                                <li>On the next page, click the link and follow the prompts for resetting the
                                    password.
                                </li>
                                <li>After receiving confirmation of a successful password reset, click the link for
                                    Universal
                                    Licensing
                                    System (DO NOT click the CORES Public Interface link.)
                                </li>
                                <li>Click the yellow ULS License Manager button to return to the Log In page.</li>
                                <li>Enter the FRN and password and click the Submit button.</li>
                            </ul>

                            <v-img
                                :src="require('../../assets/License_Manager_Login.png')"
                                alt="FCC Login Page"
                                class="my-5 elevation-10"></v-img>
                        </li>

                        <li>On the left side of the page, click the Apply for a New License link.</li>
                        <li>Choose “ZA – General Mobile Radio Service (GMRS)” from the radio service drop down list.
                        </li>
                        <li>Click Continue to navigate through the application.</li>
                        <li>On the Summary page, review your application and click the Continue to Certify button to
                            continue.
                        </li>
                        <li>On the Certification page, sign your application by typing your name in the boxes provided
                            and
                            click
                            the Submit button. (The Title box is optional.)
                        </li>
                        <li>ULS will calculate the fees. Fees for online filing MUST be received within 10 calendar days
                            of
                            the
                            filing.
                        </li>
                        <li>Click the Continue For Payment Options button to choose the method of payment.</li>
                    </ol>

                    <p>Further information can be found on&nbsp;<a
                        href="https://www.fcc.gov/general-mobile-radio-service-gmrs"
                        target="_blank">General Mobile
                        Radio Service (GMRS) on the FCC website</a>.</p>
                    <p>The GMRS license rules are found in Part 95, Subpart A of the Code of Federal Regulations. The
                        rules
                        are
                        available at the&nbsp;<a
                            href="https://www.ecfr.gov/cgi-bin/text-idx?tpl=/ecfrbrowse/Title47/47tab_02.tpl"
                            target="_blank">Electronic Code of Federal Regulations</a>&nbsp;website.
                    </p>

                    <div class="text-center mb-5">
                        <InArticleAdsense
                            data-ad-client="ca-pub-2391363374702677"
                            data-ad-slot="4051888556"
                            v-if="!user.subscribed"
                        >
                        </InArticleAdsense>
                    </div>

                    <h2 class="font-weight-light mt-5 mb-3">Downloading Authorizations in ULS</h2>
                    <p>After your application has been granted, you may also download an official copy of your license
                        from
                        the
                        License Manager by following the steps below:</p>

                    <ol class="mb-5 mx-10">
                        <li>Go to&nbsp;<a href="https://wireless2.fcc.gov/UlsEntry/licManager/login.jsp"
                                          target="_blank">License
                            Manager</a>&nbsp;and
                            log in with your FCC Registration Number (FRN) and password.
                        </li>
                        <li>Click the “Download Electronic Authorizations” link on the navigation bar on the left side
                            of
                            the
                            License Manager home page.
                        </li>
                        <li>In the My Authorizations box at the bottom of the page, select the call sign(s) you wish to
                            download.
                        </li>
                        <li>Add the call signs to the Authorizations to Download box by clicking the Add button.</li>
                        <li>Click the Download button in the lower right-hand corner of the page. The download will be
                            automatically
                            converted to a PDF file, and you can choose to Open (to print) or Save
                            (to save to a desired folder).
                        </li>
                    </ol>
                    <p>If you have any further questions on a GMRS license or need additional information, please submit
                        a
                        help
                        request online to the&nbsp;<a href="https://www.fcc.gov/wireless/available-support-services"
                                                      target="_blank">FCC Licensing Support Center</a>&nbsp;or
                        call <a href="tel:+18774803201">(877) 480-3201</a>.</p>
                    <p>FCC Licensing Support Center<br>
                        8:00 AM – 6:00 PM EST, Monday - Friday</p>

                    <p class="overline">Courtesy of Midwest GMRS Repeater Network</p>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    import AdBTWR from '@/components/ads/BTWR.vue';
    import { useTitle } from '@vueuse/core';

    export default {
        props: ['user'],

        components: {AdBTWR},

        mounted() {
            useTitle('Help: Get a GMRS License - myGMRS.com');
        }
    }
</script>
